<template>
  <div>
    <v-card flat dark tile>
      <v-card-text class="text-center pt-md-8 pb-0">
        <v-container class="py-6">
          <v-row>
            <v-col class="pb-1 pt-4">
              <h1 class="text-h5 text-lg-h4 font-weight-bold white--text"> Get latest news from us! </h1>
              <p class="mb-2 my-lg-2 text-body-1 text-lg-title"> we never spam your mail </p>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" lg="5" class="mx-auto my-1 my-lg-5">
              <v-layout row wrap justify-center>
                <v-flex xs10 lg9 sm5 md5>
                  <v-form ref="subscriptionForm">
                    <v-text-field v-model="email" outlined dense hide-details clearable label="Email" @keydown.enter.prevent="addSubscription"
                    id="email" autocomplete="false" :rules="$_emailValidation" validate-on-blur>
                      <template #append v-if="xsOnly">
                        <v-icon :disabled="!email" size="20" @click="addSubscription"
                        style="margin-top: 3px;"> mdi-send </v-icon>
                      </template>
                    </v-text-field>
                  </v-form>
                </v-flex>
                <v-flex lg3 sm2 md2 class="hidden-xs-only">
                  <v-btn style="border: 1px solid white" dark class="emailSubmitBtn" @click="addSubscription" height="38"> Submit </v-btn>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider class="mx-12"></v-divider>
      <!-- <hr width="97%" style="margin: 0 auto"> -->
      <v-card-text class="pb-0">
        <v-container fluid>
          <v-row class="text-center mx-md-2">
            <v-col cols="12" lg="3" sm="6">
              <h2 class="white--text"> Why Yelagiri? </h2>
              <v-divider class="my-3"></v-divider>
              <p class="text-justify text-title px-sm-4" style="text-indent: 12%;"> Yelagiri is a peaceful and serene hill station which has rapidly progressed to become a well-known holiday destination due to the constant efforts of the Tamil Nadu tourism board. It is situated in the district of Tirupattur. It lies at a height of 920 meters over the sea level. It is a perfect destination for families, peace-seekers, couples and even adventure-enthusiasts for spending their holidays in the lap of nature and away from the disorderly and chaotic life of the city. <span class="blue--text cursor-pointer" @click="$router.push('/why-yelagiri')"> (Read more) </span> </p>
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <h2 class="white--text"> About us </h2>
              <v-divider class="my-3"></v-divider>
              <p class="text-justify text-title px-sm-4" style="text-indent: 12%;"> We have been in the tour business for some time and we offer you a range of diversity, knowledge and service. We can cater for both local tours with connections to multiple destinations, hotels, homestays and cab services. Moreover, our experts will be with you, listen to your wants and desires, and advise you accordingly. With us, you will get all the information you need to make the best decision for you and your family or friends. Let us make your tour experience unforgettable!. </p>
            </v-col>
            <v-col cols="12" lg="3" sm="6">
              <h2 class="white--text"> Helpful links </h2>
              <v-divider class="my-3"></v-divider>
              <div style="line-height: 246%;">
                <table style="margin: 0 19%;">
                  <tr class="cursor-pointer hover-red" @click="$router.push('/')">
                    <td>
                      <v-icon :size="xsOnly ? 22 : 21" class="mr-3 mb-1"> mdi-home </v-icon>
                    </td>
                    <td class="text-left">
                      <span class="text-title font-weight-medium"> Home </span>
                    </td>
                  </tr>
                  <tr class="cursor-pointer hover-red" @click="$router.push('/blogs')">
                    <td>
                      <v-icon :size="xsOnly ? 22 : 20" class="mr-3 mb-1"> mdi-bookmark-multiple </v-icon>
                    </td>
                    <td class="text-left">
                      <span class="text-title font-weight-medium"> Blogs </span>
                    </td>
                  </tr>
                  <!-- <tr class="cursor-pointer hover-red" @click="$router.push('/rooms')">
                    <td>
                      <v-icon :size="xsOnly ? 22 : 21" class="mr-3 mb-1"> mdi-bed </v-icon>
                    </td>
                    <td class="text-left">
                      <span class="text-title font-weight-medium"> Rooms </span>
                    </td>
                  </tr>
                  <tr class="cursor-pointer hover-red" @click="$router.push('/camping-and-trekking')">
                    <td>
                      <v-icon :size="xsOnly ? 22 : 21" class="mr-3 mb-1"> mdi-campfire </v-icon>
                    </td>
                    <td class="text-left">
                      <span class="text-title font-weight-medium"> Camping & Trekking </span>
                    </td>
                  </tr> -->
                  <tr class="cursor-pointer hover-red" @click="$router.push('/popular-destinations')">
                    <td>
                      <v-icon :size="xsOnly ? 22 : 21" class="mr-3 mb-1"> mdi-shape-plus </v-icon>
                    </td>
                    <td class="text-left">
                      <span class="text-title font-weight-medium"> Popular Destinations </span>
                    </td>
                  </tr>
                  <tr class="cursor-pointer hover-red" @click="$router.push('/contact-us')">
                    <td>
                      <v-icon :size="xsOnly ? 22 : 21" class="mr-3 mb-1"> mdi-headset </v-icon>
                    </td>
                    <td class="text-left">
                      <span class="text-title font-weight-medium"> Contact us </span>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- <p class="text-justify text-title" style="text-indent: 12%;"> We have been in the tour business for some time and we offer you a range of diversity, knowledge and service. We can cater for both local tours with connections to multiple destinations, hotels, homestays and cab services. Moreover, our experts will be with you, listen to your wants and desires, and advise you accordingly. With us, you will get all the information you need to to make the best decision for you and your family or friends. Let us make your tour experience unforgettable!. </p> -->
            </v-col>
            <v-col cols="12" lg="3" sm="6" class="contact-us">
              <h2 class="white--text"> Contact us </h2>
              <v-divider class="my-3"></v-divider>
              <div class="text-center mt-4" style="line-height: 222%;">
                <table style="margin: 0 auto">
                  <tr>
                    <td>
                      <a href="mailto:contact@yelagiriguide.in" class="text-decoration-none">
                        <v-icon class="mx-3"> mdi-email </v-icon>
                      </a>
                    </td>
                    <td class="text-left">
                      <a class="white--text text-decoration-none text-subtitle-2 font-weight-regular" href="mailto:contact@yelagiriguide.in"> contact@yelagiriguide.in </a>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>
                      <a href="tel:+919080497351" class="text-decoration-none">
                        <v-icon class="mx-4"> mdi-phone </v-icon>
                      </a>
                    </td>
                    <td class="text-left">
                      <a class="white--text text-decoration-none text-subtitle-2 font-weight-regular" href="tel:+919080497351"> +91 90804 97351 </a>
                    </td>
                  </tr> -->
                </table>
              </div>
              <v-layout row wrap my-5 my-lg-5 px-13 justify-center>
                <v-flex xs4 xl2 lg4 sm2>
                  <v-btn color="error" icon href="https://www.instagram.com/yelagiriguide" target="_blank">
                    <v-icon x-large> mdi-instagram </v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs4 xl2 lg4 sm2>
                  <v-btn icon href="https://www.facebook.com/yelagiriguide" target="_blank">
                    <v-icon x-large> mdi-facebook </v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs4 xl2 lg4 sm2>
                  <v-btn color="primary" icon href="https://www.linkedin.com/company/yelagiriguide" target="_blank">
                    <v-icon x-large> mdi-linkedin </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap my-5 my-lg-5 px-13 justify-center>
                <v-flex xs4 xl2 lg4 sm3>
                  <v-btn icon href="https://pinterest.com/yelagiriguide" target="_blank">
                    <v-icon x-large> mdi-pinterest </v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs4 xl2 lg4 sm3>
                  <v-btn color="primary" icon href="https://twitter.com/yelagiriguide" target="_blank">
                    <v-icon x-large> mdi-twitter </v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs4 xl2 lg4 sm3>
                  <v-btn color="error" icon href="https://www.youtube.com/@yelagiriguide" target="_blank">
                    <v-icon size="45"> mdi-youtube </v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-0">
        <div class="mx-auto">
          <p class="text-center mb-2">
            <span class="font-weight-black"> &copy; &nbsp; 2021 - 2023 </span>
            <span class="body-2 px-1"> | &nbsp; Powered by </span>
            <span
              class="font-weight-black error--text text--lighten-2 text-decoration-underline cursor-pointer"
              @click="openTechilexWeb"
            > Techilex </span>
          </p>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    addSubscription () {
      // validation handling 
      if (!(this.email && this.$refs.subscriptionForm && this.$refs.subscriptionForm.validate())) {
        this.$root.$emit('snackbar', { color: 'error', text: 'Invalid email!' })
        return
      }

      this.$_execute('post', 'subscriptions', { email: this.email })
        .then(() => {
          this.$root.$emit('snackbar', { color: 'success', text: 'Subscribed successfully!' })
          this.email = ''
          document.querySelector('#email')?.blur()
        })
    },
    openTechilexWeb () {
      window.open('https://techilex.com?ref=yg', 'blank')
    }
  }
}
</script>

<style scoped>
  h2 {
    margin-bottom: 2.7vh;
  }
  .emailSubmitBtn {
    margin-top: 0.2vh;
  }
</style>
